.backgroundColorForContentEditable{
    background-color: rgb(32, 117, 214);
    border-radius: 5%;
    padding: 10px;
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-family: 'Times New Roman', Times, serif;

}

.textCenter {
    text-align: center;
}